/* App.css */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  margin: 0;
}

html, body, #root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures body takes at least the full height */
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows #root to fill the available space */
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows .App to grow and take up space */
}

.ContentContainer {
  flex-grow: 1; /* Allows the content to grow and take up remaining space */
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%; /* Ensures header takes full width */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Define the MoneyFont */
@font-face {
  font-family: 'MoneyFont';
  src: url('./fonts/money.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Create a class for MoneyFont */
.money-font {
  font-family: 'MoneyFont', sans-serif !important;
  letter-spacing: 0.5em !important;
}